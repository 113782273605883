import React from "react";
// Customizable Area Start
import {
    Button,
    IconButton, 
    Typography, Grid, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Avatar, Box, FormControlLabel,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import { PopUpSaved, S } from "../PostCreationWebController";
import CustomTextField from "../../../../components/src/CustomTextField";
import CustomTag from "../../../../components/src/CustomTags";
import { cancle, close, thumbnail, addImage } from "../assets";
import * as Yup from "yup";
import SwitchBtn from "../../../../components/src/Switch";
type Props = {
    state: S,
    removeImage: (event: React.MouseEvent<HTMLImageElement, MouseEvent>,value: number) => void
    handleImageChange: (value: React.ChangeEvent<HTMLInputElement>) => void
    handlePreviewOpen: () => void
    handleClosePopUp : () =>void
    savePopUpData: (value: PopUpSaved) => void
    updatePost: (value: PopUpSaved, isEdited : boolean) => void
    handleChangeToggle:() => void
    handleKeyDown:(event: React.KeyboardEvent<HTMLDivElement>) => void
    handleKeyDownDes:(event: React.KeyboardEvent<HTMLDivElement>) => void
    isEditMode : boolean
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        confidentialProject: {
            fontFamily: "Silka-light",
            fontSize: "14px",
            fontWeight: 300,

        },
        experienceInnerDiv1: {
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "1195px",
            background: "#000",
            borderRadius: "4px, 4px, 0px, 0px",
            fontSize: "14px !important",
            fontWeight: 500,
            fontFamily: "Silika-Light",
            lineHeight: "19.3px",
            color: "white",
            alignItems: "center"
        },
        buttonOutline: {
            borderRadius: "2px",
            borderColor: "black",
            marginTop: "20px",
            textTransform: "none"
        },
        dialougeHeading: {
            fontFamily: "Silika-Medium",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "19px",

        },
        inputTitle: {
            fontFamily: "Silika-Medium",
            fontSize: "16px",
            fontWeight: 500,
            marginTop: "2%",
            color: "#010101"
        },
        buttonFilled: {
            borderRadius: "2px",
            backgroundColor: "black",
            color: "white",
            marginTop: "20px",
            textTransform: "none"
        },
        switch: {
            position: "relative",
            display: "inline-block",
            width: "48px",
            height: "28px",
        },
        slider: {
            position: "absolute",
            cursor: "pointer",
            top: 0,
            left: 0,
            right: "15px",
            bottom: 0,
            backgroundColor: "#00FFA7",
            width: "48px",
            borderRadius: "50px",
        },
        confidentialDiv: {
            marginTop: "15px"
        },
        confidentialMainTask: {
            fontFamily: "Silika-Medium",
            fontSize: "16px",
            fontWeight: 500,
            color: "#000000"
        },
        confidentialSubTask: {
            fontFamily: "Silika-ExtraLight",
            fontSize: "14px",
            fontWeight: 300,
        },
        imageGridStyle: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center"
        },
        imageGridStyleText: {
            fontFamily: "Silika-Medium",
            fontSize: "16px",
            fontWeight: 500,
            color: "#010101"
        },
        imageDisplay: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: 'row',
            flexWrap  :"wrap"
        },
        borderOfImage: {
            border: "1px dashed #9B9B9D",
            borderRadius: "0px",
            marginRight: "5px",
            position: 'relative',
        },
        crossImage: {
            position: 'absolute',
            top: 1,
            right: 1,
            cursor: "pointer",
        },
        errorMessage : {
            fontFamily : "Silika-Light",
            fontWeight : 300,
            fontSize : "14px",
            lineHeight : "19.69px",
            color : "#DC2626",
            marginTop : "12px"
        }

    })
);





function PostCreationPopUp(props: Props) {
    const { state, removeImage, handleImageChange, handlePreviewOpen, savePopUpData, updatePost, handleChangeToggle, handleKeyDown, handleKeyDownDes, isEditMode, handleClosePopUp } = props;
    const classes = useStyles();
    console.log("images selected", state.uploadMultipleImagesAPI)
    console.log("state", state)
    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={state.displayDialoug}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className={classes.experienceInnerDiv1}>
                <DialogTitle className={classes.dialougeHeading}>
                    {isEditMode ? "Edit Post" : "Add Post"}
                </DialogTitle>
                <img src={close} style={{ width: "20px", height: "20px", marginRight: "20px", cursor: "pointer" }} id="close-img" onClick={handleClosePopUp}></img>
            </div>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Formik
                        initialValues={state.addPost}
                        validationSchema={Yup.object().shape({
                            projectTitle: Yup.string().required("Project Title is required"),
                            description: Yup.string().required("Description is required"),
                        })}
                        onSubmit={(values) => {
                            updatePost(values, isEditMode); 
                        }}
                    >
                        {({
                            handleChange,
                            setFieldValue,
                            values,
                            errors,
                            touched,
                            isSubmitting,
                            isValid
                        }) => {
                            return (
                                <Form>
                                    <Grid container sm={12} item className={classes.imageGridStyle}>
                                        {state.uploadMultipleImagesAPI.length > 0 ? (
                                            <Grid item className={classes.imageDisplay}>
                                                {state.uploadMultipleImagesAPI.map((srcImage: File, index: number) => {
                                                    return (
                                                        <Box className={classes.borderOfImage}>
                                                            {srcImage.type.includes("image") && (
                                                                <>
                                                                    <img width="70px" height="70px" src={URL.createObjectURL(srcImage)} style={{ padding: "10px" }}></img>
                                                                    <img onClick={(event) => removeImage(event, index)} id="remove-image" className={classes.crossImage} src={cancle} alt="Cross" />
                                                                </>
                                                            )}
                                                            {srcImage.type.includes("video") && (
                                                                <>
                                                                    <video height={72} width={80} controls style={{ padding: "10px" }}>
                                                                        <source src={URL.createObjectURL(srcImage)} type={srcImage.type} />
                                                                    </video>
                                                                    <img onClick={(event) => removeImage(event, index)} id="remove-image1" className={classes.crossImage} src={cancle} alt="Cross" />
                                                                </>
                                                            )}
                                                        </Box>
                                                    );
                                                })}

                                                <IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="label"
                                                    className={classes.borderOfImage}
                                                >
                                                    <input
                                                        type="file"
                                                        hidden
                                                        name="uploadMultipleImages"
                                                        accept="video/*,image/*"
                                                        className="filetype"
                                                        id="group_image1"
                                                        onChange={(event) => handleImageChange(event)}
                                                        multiple
                                                    />
                                                    <Avatar
                                                        style={{ borderRadius: 0, width: 66, height: 66 }}
                                                        className="profile-avatar"
                                                        src={addImage}
                                                    ></Avatar>
                                                </IconButton>
                                            </Grid>
                                        ) : (
                                            <>
                                                <IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="label"
                                                    className="icon-btn-1"
                                                >
                                                    <input
                                                        type="file"
                                                        hidden
                                                        name="uploadMultipleImages"
                                                        accept="video/*,image/*"
                                                        className="filetype"
                                                        id="group_image"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleImageChange(event)}
                                                        multiple
                                                    />
                                                    <Avatar
                                                        className="profile-avatar"
                                                        style={{ width: 70, height: 70 }}
                                                        src={thumbnail}
                                                    ></Avatar>
                                                </IconButton>
                                                <br />
                                                {state.imageSize && <Typography style={{ color: "#DC2626", fontFamily: "Silika-Light", fontSize: "14px" }}>No upload can be greater than 5MB</Typography>}
                                                {state.uploadMultipleImages.length == 0 && <Typography className={classes.imageGridStyleText}>Select Media From Library<span style={{ color: "red" }}>*</span></Typography>}
                                            </>
                                        )}
                                        {state.imageSize &&
                                            <Typography className={classes.errorMessage}>
                                            No upload can be greater than 5MB
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid item container sm={12} spacing={2}>
                                        <Grid container item sm={12} style={{ paddingLeft: "18px" }} spacing={2}>
                                            <Field
                                                name="projectTitle"
                                                component={CustomTextField}
                                                type="string"
                                                variant="outlined"
                                                title="Project Title"
                                                placeHolder=""
                                                title1="*"
                                                handleKeyDown={handleKeyDown}
                                                stateBold={state.boldITitle}
                                                stateItalic={state.italicTitle}
                                                id="profileTitel"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={12} container spacing={2}>
                                        <Grid container item sm={12} style={{ paddingLeft: "18px" }} spacing={2}>
                                        <Field
                                            name="description"
                                            component={CustomTextField}
                                            type="string"
                                            variant="outlined"
                                            title="Description"
                                            placeHolder=""
                                            title1="*"
                                            multiline={true}
                                            rows={4}
                                            stateBold={state.bolddescription}
                                            stateItalic={state.italicdescription}
                                            handleKeyDown={handleKeyDownDes}
                                            InputProps={{
                                                style: { height: "200px", overflowY: "auto" } 
                                            }}
                                        />

                                        </Grid>
                                    </Grid>
                                    {!isEditMode &&
                                    <Grid style={{ paddingLeft: "10px" }} item container sm={12} spacing={2}>
                                        <Field
                                            name="collabarators"
                                            component={CustomTag}
                                            type="string"
                                            variant="outlined"
                                            title="Collaborators"
                                            placeHolder=""
                                            inputProps={{ maxLength: 60 }}
                                            options={state.getTagsList}
                                            value={values.collabarators}
                                            onKeyDown={handleKeyDown}
                                            extractEmailDomain={true}
                                            isCreatable={true}
                                        />
                                    </Grid>
                                     }
                                    <Grid style={{ paddingLeft: "10px" }} item container sm={12} spacing={2}>
                                        <Field
                                            name="tags"
                                            component={CustomTag}
                                            type="string"
                                            variant="outlined"
                                            title="Tags"
                                            placeHolder=""
                                            options={state.tags}
                                            isTag={true}
                                        />
                                    </Grid>
                                    <Grid item container sm={12} className={classes.confidentialDiv} style={{ marginTop: "30px" }}>
                                        <Typography className={classes.confidentialMainTask} style={{ marginRight: "3%" }}>Confidential project</Typography>
                                        <FormControlLabel
                                            onChange={handleChange}
                                            control={<SwitchBtn checked={state.postData.attributes.confidential}  handleChangeToggle={handleChangeToggle} />}
                                            label=""
                                            name="confidential"
                                        />
                                        <Typography className={classes.confidentialProject} style={{ alignSelf: "center" }}>Confidential projects are only visible to collaborators</Typography>
                                    </Grid>
                                    <DialogActions>
                                        <Button disabled={values.projectTitle === "" || values.description === "" || state.uploadMultipleImagesAPI.length === 0} variant="outlined" className={classes.buttonOutline} id="preview-id" onClick={() => {
                                            savePopUpData(values)
                                            handlePreviewOpen()
                                        }}>Preview</Button>
                                        <Button disabled={values.projectTitle === "" || values.description === "" || state.uploadMultipleImagesAPI.length === 0} id="submitting" variant="contained" type="submit" className={classes.buttonFilled}>
                                            {isEditMode ? "Update" : "Publish"}
                                        </Button>
                                    </DialogActions>
                                </Form>
                            );
                        }}
                    </Formik>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}


export default PostCreationPopUp
