import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
interface ImageAttributes {
    id: number;
    name: string;
    image: string;
}
interface MediaImage {
    id: string;
    type: string;
    attributes: ImageAttributes;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    images: MediaImage[];
    // Customizable Area End
}
interface SS {
    navigation: any;
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
export default class LandingPage6Controller extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getMediaGalleryApiID: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.state = {
            // Customizable Area Start
            images : []
            // Customizable Area End
        };
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        // Customizable Area End
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            )
      
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            if (responseJson && !responseJson.errors) {
              if (apiRequestCallId === this.getMediaGalleryApiID) {
                this.setState({images : responseJson.data})
              } 
            }
      
          }
        // Customizable Area End
    }

    // Customizable Area Star
    async componentDidMount() {
        this.getMediaGallery()
    }
    getMediaGallery = async () => {
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getMediaGalleryApiEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        this.getMediaGalleryApiID = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    // Customizable Area End
}
// Customizable Area Start
// Customizable Area End