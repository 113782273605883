import React from "react";
// Customizable Area Start
import PostCreationWebController, { Props } from "./PostCreationWebController"
import { Grid,Typography,
        Container,
        Box,
        Button,
        Dialog,
        DialogActions, 
        DialogContent, 
        DialogContentText, 
        Avatar, 
        DialogTitle,
        IconButton,
        Chip,
        TextField,
        TextareaAutosize,
        Tooltip
      } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles"
import HeaderNavbar from "../../../components/src/Navbar"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline"
import { likeImage, shareImage, saveImage, favIcon, optionIcon, removeIcon, addIcon, dislikeImage, CopyImage, ChatIcon, BackBtn, } from "../../customisableuserprofiles/src/assets";
import PostCreationPopUp from "./components/PostCreationPopUp"
import Preview from "./components/Preview"
import {returnTruthyString } from "../../../components/src/HelperUtils"
import {cancle} from "./assets";
import "./PostCreation.css";
import AddCollabarator from "./components/AddCollabarator";
import DeleteCollab from "./components/DeleteCollab";
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import { DialogActionWrapper, DialogContentTextWrapper, DialogContentTextWrapperChat, StyledBox } from "../../../blocks/events/src/EventView.web";
import ClearIcon from '@material-ui/icons/Clear';
import { Autocomplete } from "@material-ui/lab";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
const ProfileAvatar = styled(Avatar)({
  backgroundColor: "#DFDFDF",
  color: "#9B9B9D",
  fontFamily: "Silika-Medium !important",
  "& .MuiAvatar-img": {
    objectFit: "fill",
  }
});

const StyledShareChatDial = styled(Dialog)({
  "& .headingText": {
    fontFamily: "Silika-Medium !important"
  }
})

export const dropdownLinks = (navText: string, handleLink : ()=>void) => {
  return (
    <div >
      <div>
        <div  onClick={handleLink}
         style={{...webStyle.discoverHyperLink}}>
          {navText}
        </div>
      </div>
      <Box style={webStyle.discoverDropDownWidth}>
        <Typography
          style={webStyle.discoverOptionBorder}
        />
      </Box>
    </div>
  )
}
// Customizable Area End

export default class ListPost extends PostCreationWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
   // Customizable Area Start
  renderCopyStatusFun() {
    return this.renderCopyStatus() && <Typography className="isNoErrorTextStyle">{this.renderCopyStatus()}</Typography>
  }

  renderCopyStatus() {
    if (this.state.msgSend) {
      return "Message sent!"
    }
    if (this.state.isCopied !== null) {
      return this.state.isCopied ? "Link copied!" : "Copy error to clipboard";
    }
  }

  ShareLinkDialoge = () => {
    return (<StyledShareChatDial
      open={this.state.isOpenModelShare}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      keepMounted
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Box sx={webStyle.modelHeadingstyle}>
              <Box sx={webStyle.headingText} className="headingText">
                Share
              </Box>
              <Box>
                <IconButton data-testId="clsoePopupModelId" onClick={this.handleClose}>
                  <ClearIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentTextWrapper id="alert-dialog-slide-description">
          <Box className="text-field-contant">
            <Box className="textfieldCopy"><Typography className="textfieldCopy-input">{this.state.linkShare}</Typography>
              <IconButton onClick={this.handleClickCopy} className="copyImg-contant" data-testId="copyBtnId">
                <img src={CopyImage} className="copyImg" />
              </IconButton>
            </Box>
          </Box>
          {this.renderCopyStatusFun()}
        </DialogContentTextWrapper>
      </DialogContent>
      <DialogActionWrapper>
        <Button onClick={this.handleChat} className="copyBtnStyle" data-testId="chatModelId">
          <img src={ChatIcon} />
        </Button>
      </DialogActionWrapper>
    </StyledShareChatDial>
    );
  }

  getButtonClass() {
    return this.state.shareData?.length === 0 ? 'opacity-05' : '';
  }

  ShareLinkChat = () => {
    return (<Dialog
      maxWidth="sm"
      fullWidth
      onClose={() => { }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      open={this.state.isSendLinkToChatModalOpen}
      keepMounted
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Box sx={webStyle.modelHeadingstyleChatModel}>
              <Box onClick={this.handleNavigateBack} data-testId="navigateBackId"><img src={BackBtn} /></Box>
              <Box sx={webStyle.headingText}>
                Share
              </Box>
              <Box>
                <IconButton onClick={this.handleCloseChatModel} data-testId="closePopupModelId">
                  <ClearIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentTextWrapperChat id="alert-dialog-slide-description">
          <Box className="DialogContentDiv">
            <Typography variant="subtitle1">To:</Typography>
            <Autocomplete
              multiple
              id="tags-outlined"
              value={this.state.shareData}
              data-testId="autoId"
              options={this.state.getAllData?.map((option: { attributes: { first_name: string, last_name: string, photo: string } }) => ({
                firstName: option?.attributes?.first_name,
                lastName: option?.attributes?.last_name,
                photo: option?.attributes?.photo,
              }))}
              getOptionLabel={(option: { firstName: string, lastName: string, photo: string }) => `${option.photo} ${option.firstName} ${option.lastName}`}
              renderOption={ (option,props) => (
                <StyledBox component={"span"} {...props}>
                  <img className="imageLogoStyle" src={option?.photo}  height={24} width={24}  alt="avtrrrr" />
                  <Box style={{fontFamily:"Silika-Medium"}}>{`${option?.firstName} ${option?.lastName}`}</Box>
                </StyledBox>
              )}
              onChange={this.handleshareDataChatChange}
              renderTags={(value, getTagProps) => (
                value?.map((option, index) => (
                  <Chip
                    label={
                      <Box component="span" className="chip-box-style">
                        <img className="imageLogoStyle" width={24} height={24} src={option?.photo} alt="AvatarLogo" />
                        <Box>{option.firstName} {option.lastName}</Box>
                      </Box>
                    }
                    {...getTagProps({ index })}
                    variant="outlined"
                  />
                ))
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Search by name"
                />
              )}
            />
          </Box>
          <TextareaAutosize
            className="textAreaStyle"
            aria-label="minimum height"
            minRows={4}
            value={this.state.sendChatMessage}
            data-testId="send"
            onChange={this.handlesendChatMessage}
            placeholder="Write a message"
          />
        </DialogContentTextWrapperChat>
      </DialogContent>
      <DialogActionWrapper>
        <Button onClick={this.handleSend} className={`SendBtnStyle ${this.getButtonClass()}`} data-testId="sendDataId" disabled={this.state.shareData?.length === 0}>Send</Button>
      </DialogActionWrapper>
    </Dialog>)
  }
  // Customizable Area End 
  render() {
    // Customizable Area Start
    const post = this.state.postData.attributes
    const profileImage = returnTruthyString(post.profile.data.attributes.photo)
    const avatarName = returnTruthyString(post.profile.data.attributes.first_name[0]) + returnTruthyString(post.profile.data.attributes.last_name[0])

    const confirmDeletePost = () => {
      return (
          <Dialog
              style={{ padding: "20px" }}
              open={this.state.deleteModal}
              onClose={this.handleDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogContent style={webStyle.headerContent}>
                  <DialogContentText id="alert-dialog-description" style={{...webStyle.textStyles, textAlign : "center"}}>
                      Are you sure you want to delete this post?
                  </DialogContentText>
              </DialogContent>
              <DialogActions style={{ margin: "10px 20px" }}>
                  <Button variant="outlined" id="close-delete-collab-popup" data-id="cancelButton" style={{...webStyle.buttonstyles, textTransform : "none"}} onClick={this.handleDelete}>Cancel</Button>
                  <Button style={{...webStyle.containedButton, textTransform : "none"}} variant="contained" id="yes-delete-btn" onClick={this.handleDeletePost} >
                      Yes, delete
                  </Button>
              </DialogActions>
          </Dialog>
      )
  }
  const isLiked = this.state.allLikes.some(
    (like: any) =>
      like.attributes.likeable_id === post.profile.data.attributes.id
  );


  const isSavedProfile = this.state.allSavedProfile.some(
    (like: any) =>
      like.attributes.favouriteable_id === post.profile.data.attributes.id
  );
  const isSavedPost = this.state.allSavedPost.some(
    (like: any) =>
      like.attributes.favouriteable_id === post.id
  );
    return (
      <ThemeProvider theme={theme}>
        <HeaderNavbar navigation={this.props.navigation}/>
        <DeleteCollab state={this.state} handleDelete={this.handleDeleteCollab} deleteCollabApi={this.deleteCollabApi} />
        <AddCollabarator state={this.state} closeDialougCollab={this.closeDialougCollab} handleAddCollab={this.handleAddCollab} />
        {this.state.editPostPopUp &&
          <PostCreationPopUp handleKeyDownDes={this.handleKeyDownDes} handleKeyDown={this.handleKeyDown}  handleChangeToggle={this.handleChangeToggle} updatePost={this.updatePost} state={this.state} removeImage={this.removeImage} handleImageChange={this.handleImageChange} handlePreviewOpen={this.handlePreviewOpen} savePopUpData={this.savePopUpData} isEditMode={true} handleClosePopUp={this.handleDisplayClose} />
        }
        {this.state.displayPreview &&
          <Preview   state={this.state} updatePost={this.updatePost} handlePreviewClose={this.handlePreviewClose} isEdited={true} profileImage={post.profile.data.attributes.photo}/>
        }
        
        {this.state.deleteModal && (
                        <div data-id="deleteModalOverlay" style = {webStyle.deleteModalOverlay}>
                          {confirmDeletePost()}
                        </div>
          )}
        <div onClick={this.handleClick}>
        <Container  style={webStyle.boxContainer} className="boxContainerMob">
          <div style={webStyle.profileDiv} className="profileDivMob">
            <div style={webStyle.profileimgContainer} className="profileImgMob">
              <ProfileAvatar src={profileImage} style={webStyle.profileImgStyle}>
                {avatarName}
              </ProfileAvatar>
            </div>
            <div style={webStyle.userNameDiv}>
              <div className="descNameMob">
                <Typography style={webStyle.describtionInterior}>{post.project_title}</Typography>
                <Typography style={webStyle.userNameInterior}>
                 {post.profile.data.attributes.first_name+" "+post.profile.data.attributes.last_name}
                </Typography>
              </div>
            </div>
            <div style={webStyle.rightAlignOption} className="rightAlignOptionMob">
            <img
              onClick={this.handleOptionOpen}
              src={optionIcon}
              alt="option"
              style={webStyle.headerImage}
            />
            {this.state.optionModal && (
              <div
                data-id="optionBoxModal"
                onClick={this.handleOptionClose}
                style={webStyle.optionHyperLinkDiv}
              >
                {dropdownLinks("Edit", ()=>this.handleEditOpen(this.state.postData))}
                {dropdownLinks("Delete", ()=>this.handleDeletePostPopUp(this.state.postData.id))}
              </div>
            )}
            <img
              onClick={()=>this.props.navigation.navigate("ProfileInfo")}
              src={cancle}
              alt="option"
              style={webStyle.headerImage}
            />
          </div>

            
          </div>
          <div style={webStyle.postImgDiv} className="postImgDivMob">
          <img
                style={{...webStyle.postImg, objectFit: 'cover'}}
                className="postImgMob"
                src={post.images[0]?.url}
                alt="postImage"
                data-test-id="post-image"
              />
          </div>
          <div style={webStyle.postShareButton} className="shareBtnDivMob">
            <div style={{ display: "flex" }}>
              {this.state.isPostLiked?
              <FavoriteIcon height={24} onClick={()=>this.likePost("unlike")} width={24} data-test-id="likeIcon">
              </FavoriteIcon>
              :
              <FavoriteBorderIcon height={24} onClick={()=>this.likePost("like")} width={24} data-test-id="unLikeIcon">
              </FavoriteBorderIcon>
              }
               {sessionStorage.getItem("profileId")===post.profile.data.attributes.id.toString() &&
                <Typography style={{ paddingLeft: "12px", paddingTop: "4px" }}>
                  {post.like_counts} Likes
                </Typography>
              }
            </div>
            <div>
              <img src={shareImage} data-test-id="sharePostBtn" onClick={()=>{this.openPopUpShareModal(this.id, "PostDetails")}} height={24} width={24} style={webStyle.imgPaddingLeft}>
              </img>
              { !isSavedPost ? <img src={saveImage} height={24} width={24} 
                  onClick={()=>this.handleAddFavouriteProfile(post.id, "BxBlockPosts::Post")} data-test-id="savePostImg"></img>:
                  <TurnedInIcon onClick={()=>this.handleDeleteFavProfile(post.id, "post")} data-test-id="unSavePostImg" />}
            </div>
          </div>
          <div style={webStyle.descBox} className="descBoxMob">
            <Typography style={webStyle.description}>
            {post.description}
            </Typography>
          </div>
          {post.images.slice(1)?.map((image, index) =>  {
            return(
            <div style={webStyle.postImgDivNew} data-test-id="post-img-div" className="postImgDivMob">
               <img
                style={{...webStyle.postImg, objectFit: 'cover'}}
                className="postImgMob"
                src={image.url}
                alt="postImage"
                data-test-id="post-image"
              />
            </div>
          )})}
           <div style={webStyle.borderDiv}>
                <Typography style={webStyle.borderBottomNew} className="collabBtmBorderMob"/>
              </div>
              <div className="tagsContainerMob">
              <Typography style={webStyle.collabHeader} className="tagHeaderMob">Project Tags</Typography>
              <Grid style={{ margin: "20px 100px", display: "flex", gap: "20px" }} className="tagMob" spacing={1}>
            {post.tag_list.map((tag, index) => (
              <Grid spacing={1}
                style={webStyle.tagCard}
                data-test-id="tag-grid-item"
                className="tagCardMob"
              >
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography style={webStyle.tagContent}>{tag}</Typography>
                </div>
              </Grid>
            ))}
          </Grid>
          </div>
              <div style={webStyle.borderDiv}>
                <Typography style={webStyle.borderBottomNew} className="collabBtmBorderMob"/>
              </div>
              <div className="tagsContainerMob">
         <Box style={webStyle.collabAddBox}>
         <Typography style={webStyle.collabHeader} className="tagHeaderMob">Collaborators</Typography>
            <img
              onClick={() => this.openDialougCollab()}
              src={addIcon}
              alt="addCollab"
              data-test-id = "addCollabPopup"
              style={webStyle.addCollabIcon}
            />
         </Box>
          <Grid style={{ margin: "20px 100px", display: "flex",flexWrap: "wrap", gap: "24px" }} className="collabDivMob" spacing={1}>
            {post.collaborators.map((collaborator, index) => (
              <div>
                {collaborator.profile &&
              <Grid spacing={1}
                style={webStyle.collabCard}
                data-test-id="collab-grid-item"
                className="collabBoxMob"
              >
              <div style={{ float: "right" }} className="deleteIconMob">
                <img
                  data-test-id="deleteIcon"
                  onClick={() => { this.setState({ deleteCollabPopUp: true , deleteID:collaborator.id}) }
                
                }
                  src={removeIcon}
                  style={webStyle.deleteCollabIcon}
                />
             </div>
                <div style={{ height: "150px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <ProfileAvatar src={collaborator.profile.photo} style={webStyle.collabImgStyle}>
                {returnTruthyString(collaborator.profile.first_name[0]) + returnTruthyString(collaborator.profile.last_name[0])}
              </ProfileAvatar>
                </div>
                <div className="collabTlDescMob">
                <Typography style={{ color: '#0D0C22', fontSize: '20px', fontWeight: 500, lineHeight: '40px' }}>
                  {collaborator.profile.first_name+" "+collaborator.profile.last_name}
                </Typography>
                <Typography style={webStyle.collabBtmBorder} className="collabBtmBorderMob"></Typography>
                <Typography style={{ color: '#0D0C22', fontSize: '14px', fontWeight: 300, lineHeight: '19.69px', paddingTop: "10px" }} className="headlineMob">
                  {post.profile.data.attributes.headline}
                </Typography>
                </div>
              </Grid>}
            </div>
            ))}
          </Grid>
          </div>
          <div style={webStyle.borderDiv}>
                <Typography style={webStyle.borderBottomNew} className="collabBtmBorderMob"/>
              </div>
              <div style={webStyle.outerMostContainerSize} className="outerMostMobileSize">
          <div style={webStyle.profileinfoDiv} className="imgDivMob">
            <div className="user-profile-img">
            <ProfileAvatar src={profileImage} style={webStyle.profileImg} className="profileImgMob">
                {avatarName}
              </ProfileAvatar>
            </div>
            <div style={{width: '100%'}}>
              <div style={webStyle.userNameDiv} className="userNameMob">
                <div className="mobDescNameMob">
                  <Typography style={webStyle.userName}>{post.profile.data.attributes.first_name+" "+post.profile.data.attributes.last_name}</Typography>
                  <Typography style={webStyle.describtion}>
                  {post.profile.data.attributes.headline+" | £"+post.profile.data.attributes.hourly_rate_from+" - £"+post.profile.data.attributes.hourly_rate_to}
                  </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                </div>
              </div>

              <div style={webStyle.borderDiv}>
                <Typography style={webStyle.borderBottom} className="collabBtmBorderMob"/>
              </div>
              <div className="table-description-wrapper">
              <div style={webStyle.levelOuterDiv}>
                <div style={webStyle.levelDiv}>
                  <Typography style={webStyle.level}>Level:</Typography>
                  <Typography style={webStyle.levelPosition}>{post.profile.data.attributes.seniority_level}</Typography>
                </div>
                <div style={webStyle.locationDiv}>
                  <Typography style={webStyle.location}>Location:</Typography>
                  <LocationOnIcon style={webStyle.locationIcon} />
                  <Typography style={webStyle.locationName}>
                  {post.profile.data.attributes.city+", "+post.profile.data.attributes.country}
                  </Typography>
                </div>
              </div>
              <div style={webStyle.borderDiv}>
                <Typography style={webStyle.borderBottom} />
              </div>
              <div style={webStyle.typeOuterDiv}>
                <div style={webStyle.typeDiv}>
                  <Typography style={webStyle.type}>Type:</Typography>
                  <Typography style={webStyle.workingType}>
                  {post.profile.data.attributes.work}
                  </Typography>
                </div>
                <div style={webStyle.professionDiv}>
                  <Typography style={webStyle.profession}>Profession:</Typography>
                  <Typography style={webStyle.professionType}>
                  {post.profile.data.attributes.profession}
                  </Typography>
                </div>
              </div>
              </div>
              <div className="table-mobile-wrapper">
                      <div
                        style={webStyle.levelOuterDiv}
                        className="locationOuterDiv"
                      >
                        <div style={webStyle.levelDiv}>
                        <Typography style={webStyle.level}>Level:</Typography>
                        <Typography style={webStyle.levelPosition}>{post.profile.data.attributes.seniority_level}</Typography>
                        </div>
                        <div className="typeMobileDiv">
                        <Typography style={webStyle.type}>Type:</Typography>
                        <Typography style={webStyle.workingType}>
                        {post.profile.data.attributes.work}
                        </Typography>
                        </div>
                        <div className="typeMobileDiv">
                          <Typography style={webStyle.profession}>
                            Profession:
                          </Typography>
                          <Typography style={webStyle.professionType}>
                          {post.profile.data.attributes.profession}
                          </Typography>
                        </div>
                        <div style={webStyle.locationDiv}>
                        <Typography style={webStyle.location}>Location:</Typography>
                        <LocationOnIcon style={webStyle.locationIcon} />
                        <Typography style={webStyle.locationName}>
                        {post.profile.data.attributes.work_location}
                        </Typography>
                        </div>
                      </div>
                    </div>
              <div style={webStyle.borderDiv}>
                <Typography style={webStyle.borderBottom} className="collabBtmBorderMob"/>
              </div>
            {sessionStorage.getItem("profileId")!==post.profile.data.attributes.id.toString() &&
              <div style={webStyle.shareButton} className="shareLikBtnMobile">
                    <Tooltip
                      title={
                        isLiked
                          ? "You recommend working with this person."
                          : "Do you recommend working with this person?"
                      }
                      data-testid="tooltip"
                    >
                      {isLiked ? (
                        <img
                          src={dislikeImage}
                          onClick={() =>
                           this.profileLike(post.profile.data.attributes.id)
                          }
                          style={{ cursor: "pointer" }}
                          height={24}
                          width={24}
                          data-testid="thumb-up-icon"
                        />
                      ) : (
                        <img
                          src={likeImage}
                          onClick={() =>
                            this.profileLike(post.profile.data.attributes.id)
                          }
                          style={{ cursor: "pointer" }}
                          height={24}
                          width={24}
                          data-testid="thumb-up-outlined-icon"
                        />
                      )}
                    </Tooltip>
                <div>
                  <ChatBubbleOutlineIcon height={24} width={24}
                    style={webStyle.imgPaddingLeft}
                  />
                  <img src={shareImage} data-test-id="shareEventBtn" onClick={()=>{this.openPopUpShareModal(post.profile.data.attributes.id, "ProfileInfo")}} height={24} width={24} style={webStyle.imgPaddingLeft}></img>
                 { !isSavedProfile ? <img src={saveImage} height={24} width={24} 
                    onClick={()=>this.handleAddFavouriteProfile(post.profile.data.attributes.id, "BxBlockProfile::Profile")} data-test-id="saveProfileImg"></img>:
                    <TurnedInIcon onClick={()=>this.handleDeleteFavProfile(post.profile.data.attributes.id, "profile")} data-test-id="unSaveProfileImg" />}
                </div>
              </div>
            }
            </div>
          </div>
          <div className="user-carousel">
              <Grid container spacing={1}>
                {Array.from(Array(3)).map((_, index) => (
                  <Grid
                    className="imageHover"
                    data-test-id={`collab-grid-item-${index}`}
                    style={{ position: 'relative', display: 'inline-block'}}
                    item xs={12} sm={6} md={4} key={index}
                  >
                    <img className="usercarouselImg" height={300} width={350} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRu1YIs9kie3qASJVyUA9pugf0M3cZUFZ20cQ&s' />
                    {this.state.collabImgHovered[index] &&
                        <div className="imageIcons">
                          <img src={favIcon} height={22} width={22} style={webStyle.imgPaddingLeft} />
                          <img src={shareImage} height={20} width={20} style={webStyle.imgPaddingLeft} />
                          <img src={saveImage} />
                        </div>
                     }
                  </Grid>
                ))}
              </Grid>
              {this.ShareLinkDialoge()}
              {this.ShareLinkChat()}
          </div>
          </div>
        </Container>
        </div>
      </ThemeProvider>
    );
    // Customizable Area End  
  }
}

// Customizable Area Start
export const webStyle = {
  boxContainer: {
    border: "1px solid #D9D9D9",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    marginTop: "100px",
    padding: "40px 0"
  },
  deleteModalOverlay : {
    position: "fixed" as "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  headingText: {
    width: "100%", textAlign: "center",
    fontSize: '24px',
    fontWeight: 500, color: "#010101"
  },
  modelHeadingstyle: { display: "flex",
   alignItems: "center",
   justifyContent: "center"
  },
  profileinfoDiv: {
    height: "189px",
    maxWidth: "1093px",
    display: "flex",
    gap: "47px",
    margin: "auto",
    paddingTop: "15px"
  },
  profileDiv: {
    display: "flex",
    position: "relative" as "relative",
    maxWidth: "1093px",
    gap: "40px",
    margin: "auto",
  },
  profileimgContainer: {
    maxWidth: "100px",
  },
  profileImgNew: {
    width: "60px",
    Height: "60px",
    borderRadius: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  describtionInterior: {
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    color: "#121212"
  },
  userNameInterior: {
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "normal",
    fontStyle: "normal",
    color: "#64748B"
  },
  postImg: {
    width: "100%",
    height: "500px",
    backgroundRepeat: "no-repeat"
  },
  postImgDiv: {
    padding: "30px 100px 10px 100px"
  },
  postImgDivNew: {
    padding: "10px 100px 10px 100px"
  },
  borderBottomNew: {
    borderBottom: "1px solid",
    color: "#DFDFDF",
    margin: "0 100px"
  },
  tagCard: {
    width: "134px",
    height: "33px",
    borderRadius: "2px",
    border: "1px solid #DFDFDF",
  },
  tagContent: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "14.48px",
    color: "#000000",
    paddingTop: "8px"
  },
  collabHeader: {
    width: "100%",
    fontSize: "20px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "24.13px",
    color: "#000000",
    margin: "20px 100px 10px 100px"
  },
  collabCard: {
    width: "305px",
    height: "280.5px",
    borderRadius: "2px",
    border: "1px solid #DFDFDF",
    padding: '18px'
  },
  collabProfileImg: {
    height: '100px',
    width: '100px',
    borderRadius: '50%'
  },
  collabBtmBorder: {
    borderBottom: "1px solid #DFDFDF",
    width: "100%",
  },
  postShareButton: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 100px"

  },
  profileimgDiv: {
    maxWidth: "189px",
  },
  profileImg: {
    borderRadius: "100px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "200px",
    height: "200px",
    fontSize: "50px",
  },
  userNameDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  userName: {
    fontSize: "26px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "normal",
    fontStyle: "normal",
  },
  describtion: {
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    marginTop: "18px",
  },
  borderDiv: {
    marginTop: "15px",
  },
  borderBottom: {
    borderBottom: "1px solid",
    color: "#DFDFDF",
    width: "836px",
  },
  levelOuterDiv: {
    display: "flex",
    marginTop: "14px",
    marginLeft: "50px",
  },
  levelDiv: {
    display: "flex",
    width: "50%",
  },
  level: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    color: "#9B9B9D",
    alignSelf: "center",
  },
  levelPosition: {
    fontFamily: "Silika-Medium",
    fontSize: '12px',
    lineHeight: "140.625%",
    fontStyle: "normal",
    alignSelf: "center",
    marginLeft: "13px",
  },
  locationDiv: {
    display: "flex",
  },
  location: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    color: "#9B9B9D",
    alignSelf: "center",
  },
  locationIcon: {
    height: "20px",
    width: "20px",
    flexShrink: 0,
    marginLeft: "7px",
    marginRight: "4px",
  },
  locationName: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    alignSelf: "center",
    marginLeft: "13px",
  },
  typeOuterDiv: {
    display: "flex",
    marginTop: "14px",
    marginLeft: "50px",
  },
  typeDiv: {
    display: "flex",
    width: "50%",
  },
  type: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    color: "#9B9B9D",
    alignSelf: "center",
  },
  workingType: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    alignSelf: "center",
    marginLeft: "13px",
  },
  professionDiv: {
    display: "flex",
    width: "50%",
  },
  profession: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    color: "#9B9B9D",
    alignSelf: "center",
  },
  professionType: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    alignSelf: "center",
    marginLeft: "13px",
  },
  descBox: {
    padding: "30px 100px"
  },
  description: {
    fontSize: "14px",
    fontFamily: "Silika",
    lineHeight: "16.88px",
    color: "#000000",
    paddingTop: "10px"
  },
  shareButton: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px"
  },
  imgPaddingLeft: {
    paddingRight: "10px",
    cursor: "pointer"
  },
  rightAlignOption: {
    display: "flex" as "flex",
    height : '100%',
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute" as "absolute",
    right: 0
  },
  headerImage: {
    height: "32px",
    width: "32px",
    flexShrink: 0,
    cursor: "pointer"
  },
  optionHyperLinkDiv: {
    position: "absolute" as "absolute",
    top: "50px",
    right: "5px",
    zIndex: 99999,
    background: "#FFF",
    borderRadius: "1px",
    padding: "4px",
    border: "1px solid #9B9B9D",
    gap: "2px",
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start" as "flex-start",
  },
  discoverHyperLink: {
    whiteSpace: "nowrap" as "nowrap",
    fontFamily: "Silika-Medium",
    textAlign: "center" as "center",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#0D0C22",
    padding: "9px 20px 9px 16px",
    gap: "8px",
    cursor: 'pointer'
  },
  discoverOptionBorder: {
    borderBottom: "0.6px solid #9B9B9D",
    width: "148px",
    opacity: "16%",
    margin: "auto",
  },
  discoverDropDownWidth: {
    width: "148px"
  },
  buttonstyles: {
    border: "1px solid  #010101",
    borderRadius: "2px",
    width: "50%"
},
textStyles: {
    fontFamily: "Silika-light",
    fontSize: "16px",
    fontWeight: 300,
    width: "60%",
    alignItems: "center",
    color: "#0D0C22"
},
containedButton: {
    border: "1px solid  #010101",
    borderRadius: "2px",
    backgroundColor: "#0D0C22",
    color: "white",
    width: "50%"
},
headerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
},
buttonAlignment: {
    justifyContent: "space-around"

},
outerMostContainerSize: {
  height: "600px",
  width: "100%",
  marginTop: "100px",
  marginBottom:'100px',
  paddingTop:50,
  position: 'relative' as 'relative'
},
profileImgStyle : {
  width: "68px",
  height: "68px",
  fontSize: "30px",
},
collabImgStyle : {
  width: "68px",
  height: "68px",
  fontSize: "30px",
},
collabAddBox : {
  display: "flex",
  justifyContent: "space-evenly"
},
addCollabIcon : {
    height: "32px",
    width: "32px",
    flexShrink: 0,
    cursor: "pointer",
    marginRight: "100px",
    marginTop: "20px"
},
deleteCollabIcon : {
  height: "20px",
  width: "20px",
  cursor: "pointer",
  color: "#9B9B9D"
},
modelHeadingstyleChatModel: {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
},
};
// Customizable Area End
