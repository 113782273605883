import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { FieldProps } from 'formik';
import S from "../../blocks/postcreation/src/PostCreationWebController"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: "2px",
            "& .MuiOutlinedInput-input": {
                "width": "100% !important",
                // "padding": "14px",
                "overflowY":"scroll !important",
                borderRadius: "2px",
                border: "0px solid #9C9C9C",
                background: "#FFF",
                "fontWeight": (props: {stateBold:boolean,stateItalic:boolean}) => {
                  return  props.stateBold ? 'bold' : 'lighter'
                },
                "fontStyle": (props: {stateBold:boolean,stateItalic:boolean}) => {
                  return props.stateItalic?  'italic' : 'normal'
                },
                "&:hover": {
                    border: "0px solid #DFDFDF",
                },
                "&:focus": {
                    border: "0px solid #DFDFDF",
                },
                "&::placeholder": {
                    "fontFamily": "Silika-Light",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "bold",
                    "lineHeight": "22px"
                },
                "height":"none !important"
            },
            "& .MuiOutlinedInput-root": {
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
            },
            "& .MuiFormHelperText-contained":{
                marginLeft:"0px",
               
            },
            "& .MuiOutlinedInput-multiline":{
                "minHeight":"72px",
                padding:"0px"
            },
            '& textarea': {
                padding:"0px 14px",
                resize: 'none', // Disable browser resizing
                minHeight: '0', // Allow the textarea to shrink when there's no content
                height: '50px !important',
                overflowY : "scroll"
            },
           "& .MuiInputBase-input":{
              "height":"none !important",
               color: '#010101',
           }
        },

        inputLabel: {
            color: '#010101',
            fontFamily: 'Silika-Light',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '22px',
            paddingBottom: '4px',
            marginTop:"15px"
        },
        errorText: {
            "color": "#DC2626",
            "fontFamily": "Silika-Light",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "18px",
            "marginBottom": "32px",
            "marginTop": "6px"
        }
    })
);

type Props = FieldProps & {
    placeHolder: string;
    title: string;
    multiline?: boolean;
    title1?:string;
    handleKeyDown?:(even: React.KeyboardEvent<HTMLDivElement>) => void
    stateBold?:boolean 
    stateItalic?:boolean
    maxLength?: number;
};

const CustomTextField: React.FC<Props> = ({
    field,
    form,
    placeHolder,
    title,
    title1,
    multiline = false,
    stateBold = false,
    stateItalic =false,
    handleKeyDown,
    maxLength
}) => {
    
    const classes = useStyles( { stateBold,stateItalic} );
    return (
        <>
            <InputLabel
                className={classes.inputLabel}
                htmlFor={field.name}
            >
                {title}<span style={{color:"red"}}>{title1}</span>
            </InputLabel>
            <TextField
                className={classes.root}
                fullWidth
                id={field.name}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                InputProps={{ classes: { root: classes.root }, inputProps: { maxLength: maxLength } }}
                error={!!form.errors[field.name] && !!form.touched[field.name]}
                helperText={
                    !!form.errors[field.name] &&
                    form.touched[field.name] &&
                    form.errors[field.name]
                }
                {...field}
                type=""
                placeholder={placeHolder}
                multiline={multiline}
                onKeyDown={handleKeyDown}
            />
        </>
    );
};

export default CustomTextField;
