import React from "react";

// Customizable Area Start
import {
  Box,
  Dialog,
  DialogContent,
  Typography
} from "@material-ui/core";
import { webStyle } from "../../email-account-login/src/LoginSignUp.web";
import TermsController, { Props } from "./TermsController";
import { closeIcon } from "./assets";


// Customizable Area End

export default class TermsAndConditions extends TermsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      // <Dialog open={this.props.open} style={{...termStyles.topContainer,overflowX:'hidden',position:'absolute', background: "transparent",
      // boxShadow: "none",}}>    
      <Dialog open={true}  PaperProps={{
        style: {
          margin: 0, // Custom margin
          overflowX: 'hidden',
          position: 'absolute',
          background: 'white',
          boxShadow: 'none',
        },
      }}
      style={{
        ...termStyles.topContainer,
        overflowX:'hidden',
        position:'absolute', 
        background: "transparent",
      boxShadow: "none",
      }}>       
     <DialogContent>
     <Box width={{xs : "100%", sm : "100%",  md: "360px"}} >
      <img  data-test-id="closeIcon"  onClick={this.props.onClose}
      src={closeIcon} height={20} width={20} style={{padding:'20px',float:'right',cursor:'pointer'}}/>
        <div style={termStyles.heading}>
             <Typography style={{ ...webStyle.formTitleText, 
               textAlign: "left" as "left",
               marginBottom:30}}>
               {this.state.termsAndConditionsHeading}
             </Typography>
             <div>
             <Typography style={{fontFamily:"Silika-Medium",
             marginLeft:1}}>
                   {this.state.generalSiteUsage}
               </Typography>
               <Typography style={{...webStyle.isAccountStatement,
                 marginBottom:10,
                 marginTop:10,
                 fontSize:"13px",
                 textAlign:'left',
                 color:'grey'}}>
               {this.state.lastRevised}
       </Typography>
       <Typography
       style={termStyles.description}>
      {this.state.termsAndConditionsSubHeading}
       </Typography>
       <Typography style={termStyles.agreement}>
       {this.state.yourAgreement}
       </Typography>
       <Typography 
       style={termStyles.description}>
      {this.state.description}
       </Typography>
             </div> 
           </div> 
      </Box>
     </DialogContent>
           </Dialog> 
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const termStyles = {
  topContainer: {
    height:'480px',
    // width:'550px',
    width:'100vw',
    padding:0,
    boxShadow:'5px 5px 10px rgba(0, 0, 0, 0.2), -5px -5px 10px rgba(0, 0, 0, 0.1)',
    top:'0%',
    left:'0%',
    backgroundColor: 'white',
    zIndex:999,
    borderRadius:5,
  },
  heading:{
    justifyContent: 'left',
    padding:'45px 25px'
  },
  agreement:{
    fontFamily:"Silika-Medium",
        marginLeft:1,
        marginBottom:10,
        marginTop:30,
  },
  description:{
    fontFamily:"Silika-Light",
    marginLeft:1,
    color:'#222222' 
  },
};

// Customizable Area End


