import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import LandingHeader from "../../../components/src/LandingHeader.web";
import { LandingFooter } from "../../../components/src/LandingFooter.web";
import { Box, Container, Typography } from "@material-ui/core";
// Customizable Area End

import LandingPage6Controller, {
  Props,
} from "./LandingPage6Controller";


// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Silka"
  }
});
const StyledContainer = styled(Box)({
  "& .findTalentTitleContainer": {
    paddingTop: "116px",
    paddingRight: "140px",
    paddingLeft: "140px",
    backgroundColor: "#F5F3F3",
    "@media(max-width:1280px)": {
      paddingRight: "70px",
      paddingLeft: "70px",
    },
    "@media(max-width:720px)": {
      paddingRight: "10px",
      paddingLeft: "10px",
    },
    "@media(max-width:620px)": {
      paddingRight: "5px",
      paddingLeft: "5px",
    },
  },
  "& .findTalentTitle": {
    fontFamily: "Silka",
    fontWeight: 500,
    fontSize: "64px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .findPara": {
    fontWeight: "300 !important",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "#0D0C22",
    marginTop: "24px"
  },
  "& .designResearch": {
    fontSize: "48px !important",
    justifyContent: "flex-start"
  },
  "& .paraOfDesign": {
    justifyContent: "flex-start !important",
    alignItems: "flex-start !important"
  },
  "& .getStartedBtn": {
    width: "125px",
    height: "48px",
    borderRadius: "2px",
    color: "white",
    textTransform: "none",
    fontFamily: "Silka",
    marginTop: "50px",
  },
  "& .startBtnProject": {
    backgroundColor: "#0D0C22"
  },
  "& .designResearchContainer": {
    paddingTop: "129px",
    paddingBottom: "129px",
    display: "flex",
    justifyContent: "space-between"
  },
  "& .designResearchImg": {
    weight: "441px",
    height: "313px"
  },
  "& .exteriorImg": {
    width: "570px",
    height: "411.74px"
  },
  "& .CMFImg": {
    width: "586px",
    height: "409px"
  },
  "& .uiuxImg": {
    width: "578px",
    height: "333px"
  },
  "& .creativeImg": {
    width: "445px",
    height: "313px"
  },
  "& .CADResearchImg": {
    width: "570px",
    height: "411px"
  },
  "& .imgInCategory": {
    width: "100% !important",
  }

});


// Customizable Area End

export default class LandingPage6Web extends LandingPage6Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  
  // Customizable Area End
  render() {
    // Customizable Area Start
    const images = this.state.images;
    // Customizable Area End
    return (
      // Customizable Area Start
      
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <StyledContainer>
          <LandingHeader navigation={this.props.navigation} />
          <Container maxWidth="xl" style={webStyles.parent}>
          <Typography style={webStyles.headding}>
            Gallery
          </Typography>
          <div style={{ display: 'flex', justifyContent : "center", flexWrap: 'wrap', gap: '24px'}}>
            {images?.map((item) => (
              <div
                key={item.id}
                style={{
                  padding: 0,
                  width: '302px', 
                  height: '230px',
                  boxSizing: 'border-box', 
                }}
              >
                <img
                  srcSet={`${item.attributes.image}?w=302&h=230&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.attributes.image}?w=302&h=230&fit=crop&auto=format`}
                  alt={item.attributes.name}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius : "4px"
                  }}
                />
              </div>
            ))}
          </div>
          </Container>
          
          <LandingFooter navigation={this.props.navigation} />
        </StyledContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyles = {
  parent : {
    padding : "80px"
  },
  headding : {
    fontFamily : "silika",
    fontSize : "32px",
    lineHeight : "40px",
    letterSpacing : "-0.15px",
    marginBottom : 95,
    fontWeight : 500

  }
}
// Customizable Area End
