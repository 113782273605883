import React from "react";
// Customizable Area Start
import {
  Typography, IconButton,Input,InputAdornment,Chip,
  Box} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import "../../customisableuserprofiles/src/ProfileInfo.css";
import Select, { components } from "react-select";
import { Country }  from 'country-state-city';

import { searchIcon,poundIcon } from "../../customisableuserprofiles/src/assets"
const seniorityTypes = [
    { label: "Entry",value: "entry" }, 
    { label: "Junior",value: "junior" },
    { label: "Mid-Level",value: "mid_level" },
    { label: "Senior",value: "senior" },
  ];
  const workTypes = [
    { label: "Permanent",value: "permanent" },
    { label: "Internship",value: "internship" },
    { label: "Contract",value: "contract" },
    { label: "Full-Time",value: "full_time" },
    { label: "Part-Time",value: "part_time" },
    { label: "On site",value: "on_site" },
    { label: "Remote",value: "remote" },
    { label: "Freelance",value: "freelance" },
  ];
  const professionTypes = [
    { label: "Artist",value: "artist" },
    { label: "Exterior Design",value: "exterior_design" },
    { label: "Interior Design",value: "interior_design" },
    { label: "Product Design",value: "product_design" },
    { label: "Industrial Design",value: "industrial_design" },
    { label: "UI/UX Design",value: "ui/ux design" },
    { label: "Project Management",value: "project_management" },
    { label: "CMF Design",value: "cmf_design" },
    { label: "Graphic",value: "graphic" },
    { label: "CGI",value: "cgi" },
    { label: "Brand",value: "brand" },
    { label: "Strategy",value: "strategy" },
    { label: "Animation",value: "animation" },
    { label: "Visualisation",value: "visualisation" },
  ];
  const skillTypes=professionTypes;
  const customControl = (props: any) => {
    return (
      <div style={{ position: 'relative' }}>
        <img
          src={searchIcon}
          style={{
            position: 'absolute',
            left: 10,
            top: '50%',
            transform: 'translateY(-50%)',
            height: 20,
            width: 20,
            zIndex: 1, 
          }}
        />
        <components.Control {...props} />
      </div>
    );
  };
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

import AdvanceSearchController,{Props} from "./AdvanceSearchController";
const StyledBox = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));


// Customizable Area End

export default class AdvanceSearch extends AdvanceSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const countries = Country.getAllCountries().map(state => ({ value: state.isoCode, label: state.name }));
    return (
      <ThemeProvider theme={theme}>
        <div >
          {/* Customizable Area Start  */}
          <Input style={{border:'1px solid #DFDFDF',padding:12}}
           value={this.state.advanceSearchText}
           data-test-id="advanceSearchId"
           onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleAdvanceSearchText(event.target.value)}
           startAdornment={
            <InputAdornment
              position="start"
              style={{
                width: 40,
                marginLeft: -9,
              }}
            >
              <IconButton>
                <img
                  src={searchIcon}
                  style={{
                    height: 20,
                    width:20,
                    padding:2
                  }}
                />
              </IconButton>
            </InputAdornment>
          } disableUnderline fullWidth placeholder="Name, company, position"></Input>
           <Typography style={{fontFamily:'Silika-Medium',marginTop:20}}>Filters:</Typography>

           <StyledBox style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 20, gap: '10px' }}>
                                           <Select                                             
                                                onMenuOpen={() => {
                                                    this.handleSeniorityDropDown();
                                                }}
                                                data-test-id="seniorityMenu"
                                                onMenuClose={() => {
                                                    this.handleSeniorityDropDown();
                                                }}
                                                onChange={this.handleChangeSeniority}
                                                isMulti
                                                controlShouldRenderValue={false} 
                                                options={seniorityTypes}
                                                placeholder="Seniority"
                                                styles={{
                                                    menu: (base) => {
                                                      return {
                                                        ...base,paddingTop: 5,
                                                        borderTopRightRadius: '0px',
                                                        paddingRight: 20,
                                                        color: 'black',border: 'none',   
                                                        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 8px',
                                                        borderTop: 'none',marginTop: 8,
                                                        borderTopLeftRadius: '0px', paddingLeft: 20,
                                                      };
                                                    },
                                                    singleValue:(base)=>{
                                                        return{...base,color: 'black',}
                                                    },
                                                    dropdownIndicator: (base) => {
                                                      return {
                                                        ...base,color: 'lightgrey',
                                                      };
                                                    },
                                                    control: (base) => {
                                                      return{
                                                        ...base, backgroundColor: 'white',
                                                        fontFamily: 'Silika-Light',       
                                                        height: 54,
                                                        width:150,
                                                        borderColor: '#BEBEBE'
                                                      }
                                                    },
                                                    option: (provided,state) => {
                                                      return {
                                                        ...provided,
                                                        backgroundColor: state.isSelected ? 'black' : 'inherit',
                                                        '&:hover': {
                                                            backgroundColor: '#9B9B9D',
                                                        },};},
                                                        indicatorSeparator: (styles) => ({
                                                          ...styles,
                                                          display: "none",
                                                        }),
                                                    menuList: (base) => {
                                                      return {
                                                        ...base,
                                                        marginTop: 15,
                                                        zIndex: -1,
                                                        height: 120,
                                                        fontFamily: 'Silika-Light',
                                                        fontSize: '14px',
                                                        overflowY: 'scroll', 
                                                        textAlign: 'left',
                                                        position: 'relative',
                                                        top: '-5px',
                                                      };
                                                    },
                                                  }}
                                                name="seniority"
                                                isSearchable={false}
                                                isClearable={false}
                                                isRtl={false}
                                            />
                                            <Select
                                                onMenuOpen={() => {
                                                   this.handleWorkTypeDropDown();
                                                }}
                                                onMenuClose={() => {
                                                    this.handleWorkTypeDropDown();
                                                }}
                                                data-test-id="workTypeMenu"
                                                onChange={this.handleChangeWorkTypes}
                                                placeholder="Work Type"
                                                options={workTypes}
                                                hideSelectedOptions
                                                isMulti
                                                controlShouldRenderValue={false} 
                                                styles={{
                                                  singleValue:(base)=>{
                                                    return{...base,color: 'black',}
                                                },
                                                    menu: (base) => {
                                                      return {
                                                        ...base,borderTopRightRadius: '0px', 
                                                        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 8px',
                                                        borderTop: 'none',
                                                        marginTop: 8,
                                                        paddingRight: 20,
                                                        color: 'black',  
                                                        paddingTop: 5,
                                                        paddingLeft: 20,
                                                        borderTopLeftRadius: '0px',
                                                        border: 'none',
                                                      };
                                                    },
                                                    option: (provided,state) => {
                                                        return {
                                                          ...provided,
                                                          backgroundColor: state.isSelected ? 'black' : 'inherit',
                                                          '&:hover': {
                                                              backgroundColor: '#9B9B9D',
                                                          },};
                                                        },
                                                    
                                                    indicatorSeparator: (styles) => ({
                                                        ...styles,
                                                        display: "none"}),
                                                      dropdownIndicator: (base) => {
                                                        return {
                                                          ...base,color: 'lightgrey',
                                                        };
                                                      },
                                                    control: (base) => {
                                                      return{
                                                        ...base,
                                                        backgroundColor: 'white',
                                                        fontFamily: 'Silika-Light',
                                                        height: 54,
                                                        width:150,
                                                        borderColor: '#BEBEBE'
                                                      }
                                                    },
                                                    
                                                    menuList: (base) => {
                                                      return {
                                                        ...base,
                                                        overflowY: 'scroll', 
                                                        textAlign: 'left',
                                                        marginTop: 15,
                                                        zIndex: -1,
                                                        height: 120,
                                                        fontFamily: 'Silika-Light',
                                                        fontSize: '14px',
                                                        position: 'relative',
                                                        top: '-5px',
                                                      };
                                                    },
                                                  }}
                                                name="country"
                                                isClearable={false}
                                                isSearchable={false}
                                                isRtl={false}
                                               
                                            />
                                     <Select                                             
                                                isMulti
                                                data-test-id="professionMenu"
                                                onMenuOpen={() => {
                                                  this.handleSkillsDropDown();
                                               }}
                                               onMenuClose={() => {
                                                   this.handleSkillsDropDown();
                                               }}
                                                controlShouldRenderValue={false} 
                                                options={professionTypes}
                                                components={{ Control: customControl }}
                                                placeholder="Profession"
                                                onChange={this.addProfession}
                                                styles={{
                                                    menu: (base) => {
                                                      return {
                                                        ...base,
                                                        borderTopRightRadius: '0px',
                                                        paddingRight: 20,
                                                        borderTopLeftRadius: '0px',
                                                        border: 'none',
                                                        borderTop: 'none',
                                                        marginTop: 8,
                                                        color: 'black',   
                                                        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 8px',
                                                        paddingTop: 5,
                                                        paddingLeft: 20,
                                                      };
                                                    },
                                                    placeholder: (provided) => ({ ...provided,
                                                      marginLeft:35,
                                                    }),
                                                    singleValue:(base)=>{return{...base,color: 'black',
                                                      }
                                                  },
                                                    option: (provided,state) => {
                                                        return {
                                                          ...provided,
                                                          backgroundColor: state.isSelected ? 'black' : 'inherit',
                                                          '&:hover': {
                                                              backgroundColor: '#9B9B9D',
                                                          },
                                                        }; },
                                                    dropdownIndicator: (base) => {
                                                      return {
                                                        ...base,color: 'lightgrey',
                                                      };
                                                    },
                                                    indicatorSeparator: (styles) => ({
                                                        ...styles,
                                                        display: "none",
                                                      }),
                                                      menuList: (base) => {
                                                        return {
                                                          ...base,height: 120,
                                                          marginTop: 15,
                                                          zIndex: -1,
                                                          fontFamily: 'Silika-Light',
                                                          fontSize: '14px',
                                                          position: 'relative',
                                                          textAlign: 'left', 
                                                          top: '-5px',
                                                          overflowY: 'scroll', 
                                                        };
                                                      },
                                                    control: (base) => {
                                                      return{
                                                        ...base,
                                                        backgroundColor: 'white',
                                                        fontFamily: 'Silika-Light',
                                                        height: 54,
                                                        width:220,
                                                        borderColor: '#BEBEBE'
                                                      }
                                                    },
                                                    
                                                    
                                                  }}
                                                name="profession"
                                                isSearchable={false}
                                                isClearable={false}
                                                isRtl={false}
                                          
                                            />
                                              <Select                                             
                                                isMulti
                                                onChange={this.addSkill}
                                                onMenuOpen={() => {
                                                  this.handleSkillsDropDown();
                                               }}
                                               onMenuClose={() => {
                                                   this.handleSkillsDropDown();
                                               }}
                                               data-test-id="skillsSelect"
                                                controlShouldRenderValue={false} 
                                                options={skillTypes}
                                                components={{ Control: customControl }}
                                                placeholder="Skills"
                                                styles={{
                                                    menu: (base) => {
                                                      return {
                                                        ...base,paddingRight: 20,
                                                        color: 'black',   
                                                        borderTopRightRadius: '0px',
                                                        borderTopLeftRadius: '0px',
                                                        border: 'none',
                                                        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 8px',
                                                        borderTop: 'none',
                                                        marginTop: 8,
                                                        paddingTop: 5,
                                                        paddingLeft: 20,
                                                      };
                                                    },
                                                    placeholder: (provided) => ({
                                                      ...provided,marginLeft:35,
                                                    }),
                                                    singleValue:(base)=>{
                                                        return{...base,
                                                          color: 'black',}
                                                    },
                                                    option: (provided,state) => {
                                                        return {
                                                          ...provided,
                                                          backgroundColor: state.isSelected ? 'black' : 'inherit',
                                                          '&:hover': { backgroundColor: '#9B9B9D',
                                                          },
                                                        };
                                                      },
                                                    dropdownIndicator: (base) => {
                                                      return {
                                                        ...base,color: 'lightgrey',
                                                      } },
                                                    indicatorSeparator: (styles) => ({
                                                        ...styles,
                                                        display: "none",
                                                      }
                                                      ),
                                                    control: (base) => {
                                                      return{
                                                        ...base,
                                                        backgroundColor: 'white',
                                                        borderColor: '#BEBEBE',
                                                        fontFamily: 'Silika-Light',
                                                        height: 54,
                                                        width:220,
                                                      }
                                                    },
                                                    
                                                    menuList: (base) => {
                                                      return {
                                                        ...base, marginTop: 15,
                                                        textAlign: 'left',
                                                        position: 'relative',
                                                        fontFamily: 'Silika-Light',
                                                        fontSize: '14px',
                                                        zIndex: -1,
                                                        height: 120,
                                                        overflowY: 'scroll',
                                                        top: '-5px',
                                                      };
                                                    },
                                                  }}
                                                name="skills"
                                                isSearchable={false}
                                                isClearable={false}
                                                isRtl={false}
                                          
                                            />
                                                
                                                      
                                            <Select
                                                data-test-id='selectCountry'
                                                onMenuOpen={() => {
                                                    this.handleCountryDropDown();
                                                }}
                                                onMenuClose={() => {
                                                    this.handleCountryDropDown();
                                                }}
                                                placeholder="Country"
                                                controlShouldRenderValue={false}
                                                options={countries}
                                                onChange={this.handleCountryChange}
                                                styles={{
                                                  
                                                    singleValue:(base)=>{
                                                        return{...base,color: 'black',}
                                                    }, control: (base) => {
                                                      return{
                                                        ...base,
                                                        width:150,
                                                        borderColor: '#BEBEBE',
                                                        fontFamily: 'Silika-Light',
                                                        height: 54,
                                                        backgroundColor: 'white',
                                                      }
                                                    },
                                                    
                                                     menu: (base) => {
                                                        return {
                                                          ...base,
                                                          marginTop: 8,
                                                          paddingTop: 5,
                                                          borderTopLeftRadius: '0px',
                                                          border: 'none',
                                                          paddingLeft: 20,
                                                          borderTopRightRadius: '0px',
                                                          paddingRight: 20,
                                                          color: 'black',   
                                                          boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 8px',
                                                          borderTop: 'none',
                                                        };
                                                      },
                                                      option: (provided,state) => {
                                                        return {
                                                          ...provided,
                                                          backgroundColor: state.isSelected ? 'black' : 'inherit',
                                                          '&:hover': {
                                                              backgroundColor: '#9B9B9D',
                                                          },
                                                        };
                                                      },
                                                    dropdownIndicator: (base) => {
                                                      return {
                                                        ...base,color: 'lightgrey',
                                                      };
                                                    },
                                                    indicatorSeparator: (styles) => ({
                                                        ...styles,
                                                        display: "none",
                                                      }),
                                                   
                                                    
                                                    menuList: (base) => {
                                                      return {
                                                        ...base,
                                                        height: 120,
                                                        fontFamily: 'Silika-Light',
                                                        fontSize: '14px',
                                                        overflowY: 'scroll', 
                                                        marginTop: 15,
                                                        top: '-5px',
                                                        zIndex: -1,
                                                        textAlign: 'left',
                                                        position: 'relative',  
                                                      };
                                                    },
                                                  }}
                                                name="country"
                                                isSearchable
                                                isClearable={false}
                                                isRtl={false}
                                                // onChange={(event) => { this.handleCountry(event) }}
                                            />
                                            <Input style={{border:'1px solid #BEBEBE',padding:5,borderRadius:5,width:130}}
           startAdornment={
            <InputAdornment
              position="start"
              style={{
                width: 40,
                marginLeft: 0,
              }}
            >
              <img
                  src={poundIcon}
                  style={{
                    height: 25,
                    width:25,
                    padding:2
                  }}
                />
            </InputAdornment>
          } disableUnderline
          value={this.state.minRate}
                onChange={this.handleMinRateChange}
          placeholder="Min-Rate"></Input> 
        <Input style={{border:'1px solid #BEBEBE',padding:5,borderRadius:5,width:130}}
           startAdornment={
            <InputAdornment
              position="start"
              style={{
                width: 40,
                marginLeft: 0,
              }}
            >
              <img
                  src={poundIcon}
                  style={{
                    height: 25,
                    width:25,
                    padding:2
                  }}
                />
            </InputAdornment>
          } disableUnderline 
          value={this.state.maxRate}
          onChange={this.handleMaxRateChange}  
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*', // Allows only numbers
          }}
          
          placeholder="Max-Rate"></Input> 
    </StyledBox>
    {this.state.tags.length > 0 && (
  <div style={{ borderTop: '1px solid #BEBEBE', marginTop: 20 }}>
    <div style={{ marginTop: 10,display:'flex',flexDirection: 'row',justifyContent:'space-between' }}>
     <div>
     {this.state.tags.map((tag, index) => (
        <Chip
          label={tag}
          key={index}
          data-test-id='removeTags'
          onDelete={() => this.removeTag(tag)}
          deleteIcon={<IconButton style={{ color: 'black', fontSize: '20px' }}>×</IconButton>}
          style={{ backgroundColor: "white", borderRadius: 0, border: '1px solid #BEBEBE', marginRight: 5, marginBottom: 5 }} // Add margin-right and margin-bottom here
        />
      ))}
     </div>
      <div
      data-test-id="clearFilters"
      onClick={()=>{this.clearAllFilters()}}
      style={{ float: 'right',whiteSpace:'nowrap', fontFamily: 'Silika-Light',cursor:'pointer', textDecoration: "underline", color: 'grey' }}>Clear all filters
      </div>
    </div>
  </div>
)}
          {/* Customizable End Start  */}
        </div>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
