import React from "react";
// Customizable Area Start
import {
  Container,
  Typography,
  IconButton,
  Button,
  Tooltip,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import HeaderNavbar from "../../../components/src/Navbar";
import Carousel from "react-elastic-carousel";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import "../../customisableuserprofiles/src/ProfileInfo.css";
import AdvanceSearch from "../../advancedsearch/src/AdvanceSearch.web";

import { webStyle } from "../../customisableuserprofiles/src/ProfileInfo.web";

import {
  leftArrow,
  rightArrow,
  likeImage,
  dislikeImage,
  shareImage,
  saveImage,
} from "../../customisableuserprofiles/src/assets";
import CatalogueController from "./CatalogueController";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
interface CustomArrowProps {
  onClick: () => void;
  type: string;
}
export const customArrow = ({ type, onClick }: CustomArrowProps) => {
  const iconSrc = type === "PREV" ? leftArrow : rightArrow;
  return (
    <IconButton data-test-id="iconButton" onClick={onClick}>
      <img data-test-id="icon" height={20} width={20} src={iconSrc} alt={type} />
    </IconButton>
  );
};

// Customizable Area End

export default class FindTalentCatalogue extends CatalogueController {
  render() {
    // Customizable Area Start

    return (
      <ThemeProvider theme={theme}>
        <HeaderNavbar />
        <Container>
          {/* Customizable Area Start  */}
          <Typography style={modalStyle.mainHeading}>Creatives</Typography>
          <AdvanceSearch navigation={undefined} id={""} />
          {this.state.profileData?.map((profile: any, index) => {
            const isLiked = this.state.allLikes.some(
              (like: any) =>
                like.attributes.likeable_id === profile.attributes.id
            );
            return (
              <div
                key={index}
                style={modalStyle.outerMostContainerSize}
                className="outerMostMobileSize"
              >
                <div style={webStyle.profileinfoDiv} className="img-div">
                  <div className="profile-img">
                    {profile.attributes.photo ? (
                      <img
                        src={profile.attributes.photo}
                        alt="profileImage"
                        style={webStyle.profileImg}
                        className="profileImg"
                      />
                    ) : (
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALcAAACUCAMAAADmiEg1AAAAMFBMVEXk5ueutLeqsbTn6erc3+Df4uPIzM7q7O2xt7qnrbG8wcTT1ti3vL/W2drLz9HCx8lXIWBCAAAEdklEQVR4nO2c2W7rIBBAMYNZbJb//9sLqZ02sdOwTBh65fMURap0OhqGbQhjFxcXFxcXFxcXFxcXF/kAAGNz4uvjXyB6CudlCEsiyNWJP+AOIORiNf9m0nbxQilqs98AkHaa+PRE/MKu48YcZmkOzjuGy5la8BQQfjKvrG/m2rPxYg6rfRnre74s62DiwMIxrU/EJzmUOAidYX0z19SuPwDHM7VTyB217g6sudJfDJLkpdpRfIRJCNbsHNnhjj7iMbdLtWMpF9TiIMqtI3qmFQe2VIQ7zUCk2gxklXYU96Ta5WPyLk6a4u/XJC+9LV0xBP/rAvCNuCcLuGrQjjWFTDtUZ8kNqrXh3GQd926CRLu6Bu4QZfhcX0w2b5LJp3wZeBQnSZTGUZm8A4H2bJvjPWmCuUc0hzuuZwmOVDyGd//VFSCkSSzh3RNFIYQ70r+CN61N7nRPcIfi3X2HDBjDMtJ7qm9dC+6E3t4LivYUOhcUhVIGp2npHW99eV/e/7E30rjsXgf/aP1u3hRvdD+LqD8ZfKD7nYnAWQ923xkDjndvbaZQtPtvjAGloPQuJwxnYBqCO9i6C6knb4oDwrobqZ/w3rN8AmGnZtb+2qkHotVb03QAtSYKlyTazQfJmuiavvGojeQU+ebdePZD1hWhcruTTrF0F8YtZ+CGzLpp92AkZdNP9Z0at4TWDbVQE3e11WYKXVPBhqqZNTnBuvtAubihWAceEKVjk3hM7sBcNv1wS9zMdgdKIh43C4Nop01ytvUQQ/IO+Jz279RGTV4AHwFhMxaHZiFvMD2g5NsmcD5IB/UjigX9Wp1zG9gI/dMngJD23Dxa+/FS5A7A7ML0/IiHmym4eZzqdw6wGHVjTAo859xwK2n67coBpUC4NeJE+kztkwsk1I3bR2qfdyRXNgvnVu/lho9Bd4ING/YoNrv0hFHrew3Zx2f8zi7BD5YzSUX4WEZePwbc/o04Rr3b/oIYYLPzQZvcB1PRfZEpbyjdAcQa7KFev3XnOrqTTZ6KrdZO+S/TntS1FBTZDqL5viEtaruax6T2tjQ7zsMe59Je6gBOapxb1/SscVn7mCsRmo5hz8w/ny4Ksh7kFppr9+ni4jHS+kjcwH0uWyAWPqy8fiaO0E+dqqh5QU+Rn+Z2/UQ9B+VRh+OJ+LTghxzm9mvtDHPsC2RwHw72Js4l6vDMPY5CMEc9GUJqussS13jirQ+6CsGZhGDulSM7BuM4DoqvE9pBeChIoT0hXLc1P1asg7f+hESXsn2CaWqWgA6T5Ata3tdjNR1XUX/rhvCitYHqdqDKn9rAE6985EiY3Jt4VYpjvUBr8K7rZKLWTplSXsWr+jOw0cU3LLS1ZKfiUnyEcKeAl4mDozb+oriId9zh/E5ZyzLSK5d2eNHDJNKFySO8yJva9puiBe0waZIOJvLnHqzHfjjkhxvrUSgO+bt74gXsE9lP1ceY43fyH/mo7K66LtjcqYd8w/CIzW65pjkzeUnuwBRIb7WxyPb++TPY9JizJco/D5E7V3BzAm8AAAAASUVORK5CYII="
                        alt="profileImage"
                        style={webStyle.profileImg}
                        className="profileImg"
                      />
                    )}
                  </div>
                  <div style={{ width: "100%" }}>
                    <div style={webStyle.userNameDiv} className="userName-div">
                      <div className="userDescMobileDiv">
                        <Typography style={webStyle.userName}>
                          {profile.attributes.first_name}
                        </Typography>
                        <Typography
                          style={{ ...webStyle.describtion, overflowY: "auto" }}
                          className="descTextMobile"
                        >
                          {profile.attributes.description}
                        </Typography>
                      </div>
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                        className="userName-cta"
                      >
                        <ShortlistButton variant="contained">
                          Shortlist
                        </ShortlistButton>
                        <AvailableBtn variant="contained">
                          Available
                        </AvailableBtn>
                      </div>
                    </div>

                    <div style={webStyle.borderDiv} className="borderBtmMob">
                      <Typography style={webStyle.borderBottom} />
                    </div>

                    <div className="table-description-wrapper">
                      <div style={webStyle.levelOuterDiv}>
                        <div style={webStyle.levelDiv}>
                          <Typography style={webStyle.level}>Level:</Typography>
                          <Typography style={webStyle.levelPosition}>
                            {profile.attributes.seniority_level}
                          </Typography>
                        </div>
                        <div style={webStyle.locationDiv}>
                          <Typography style={webStyle.location}>
                            Location:
                          </Typography>
                          <LocationOnIcon style={webStyle.locationIcon} />
                          <Typography style={webStyle.locationName}>
                            {profile.attributes.city}
                          </Typography>
                        </div>
                      </div>

                      <div style={webStyle.borderDiv}>
                        <Typography style={webStyle.borderBottom} />
                      </div>

                      <div style={webStyle.typeOuterDiv}>
                        <div style={webStyle.typeDiv}>
                          <Typography style={webStyle.type}>Type:</Typography>
                          <Typography style={webStyle.workingType}>
                            Full Time | Remote
                          </Typography>
                        </div>
                        <div style={webStyle.professionDiv}>
                          <Typography style={webStyle.profession}>
                            Profession:
                          </Typography>
                          <Typography style={webStyle.professionType}>
                            Interior Design | UI/UX Design | Visualisation
                          </Typography>
                        </div>
                      </div>
                      <div style={webStyle.borderDiv}>
                        <Typography style={webStyle.borderBottom} />
                      </div>
                    </div>

                    <div className="table-mobile-wrapper">
                      <div
                        style={webStyle.levelOuterDiv}
                        className="locationOuterDiv"
                      >
                        <div style={webStyle.levelDiv}>
                          <Typography style={webStyle.level}>Level:</Typography>
                          <Typography style={webStyle.levelPosition}>
                            {profile.attributes.seniority_level}
                          </Typography>
                        </div>
                        <div className="typeMobileDiv">
                          <Typography style={webStyle.type}>Type:</Typography>
                          <Typography style={webStyle.workingType}>
                            Full Time | Remote
                          </Typography>
                        </div>
                        <div className="typeMobileDiv">
                          <Typography style={webStyle.profession}>
                            Profession:
                          </Typography>
                          <Typography style={webStyle.professionType}>
                            UI/UX Design
                          </Typography>
                        </div>
                        <div style={webStyle.locationDiv}>
                          <Typography style={webStyle.location}>
                            Location:
                          </Typography>
                          <LocationOnIcon style={webStyle.locationIcon} />
                          <Typography style={webStyle.locationName}>
                            {profile.attributes.city}
                          </Typography>
                        </div>
                      </div>
                    </div>

                    <div
                      style={webStyle.shareButton}
                      className="shareLikBtnMobile"
                    >
                      <Tooltip
                        title={
                          isLiked || profile.attributes.is_like
                            ? "You recommend working with this person."
                            : "Do you recommend working with this person?"
                        }
                        data-testid="tooltip"
                      >
                        {isLiked || profile.attributes.is_like ? (
                          <img
                            src={dislikeImage}
                            onClick={() =>
                              this.handleThumbsUp(profile.attributes.id, false)
                            }
                            style={{ cursor: "pointer" }}
                            height={24}
                            width={24}
                            data-testid="thumb-up-icon"
                          />
                        ) : (
                          <img
                            src={likeImage}
                            onClick={() =>
                              this.handleThumbsUp(profile.attributes.id, true)
                            }
                            style={{ cursor: "pointer" }}
                            height={24}
                            width={24}
                            data-testid="thumb-up-outlined-icon"
                          />
                        )}
                      </Tooltip>

                      <div>
                        <ChatBubbleOutlineIcon
                          height={24}
                          width={24}
                          style={webStyle.imgPaddingLeft}
                        />
                        <img
                          src={shareImage}
                          height={24}
                          width={24}
                          style={webStyle.imgPaddingLeft}
                        />
                        <img src={saveImage} height={24} width={24} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="user-carousel" style={{ marginTop: 130 }}>
                  <Carousel
                    renderArrow={customArrow}
                    isRTL={false}
                    itemsToShow={3}
                  >
                    <img
                      className="usercarouselImg"
                      height={250}
                      width={365}
                      src="https://c4.wallpaperflare.com/wallpaper/431/451/684/the-most-beautiful-picture-of-nature-wallpaper-preview.jpg"
                    />
                    <img
                      className="usercarouselImg"
                      height={250}
                      width={365}
                      src="https://asset.gecdesigns.com/img/wallpapers/beautiful-fantasy-wallpaper-ultra-hd-wallpaper-4k-sr10012418-1706506236698-cover.webp"
                    />
                    <img
                      className="usercarouselImg"
                      height={250}
                      width={365}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRu1YIs9kie3qASJVyUA9pugf0M3cZUFZ20cQ&s"
                    />
                    <img
                      className="usercarouselImg"
                      height={250}
                      width={365}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRImuLUjNhsN6njEQtrPlj0-WCv3NQK5L-AhHnJBH0E9g&s"
                    />
                    <img
                      className="usercarouselImg"
                      height={250}
                      width={365}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTu0DoljXDEjmyMU0nDOuydrVpmdRIb0VNJ_IU-2BDUdQ&s"
                    />
                    <img
                      className="usercarouselImg"
                      height={250}
                      width={365}
                      src="https://st.depositphotos.com/2001755/3622/i/450/depositphotos_36220949-stock-photo-beautiful-landscape.jpg"
                    />
                    <img
                      className="usercarouselImg"
                      height={250}
                      width={365}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTk0b7TxuIr2-tR1Y2r6fLAonLoFPH274NNeg&s"
                    />
                  </Carousel>
                </div>
              </div>
            );
          })}
          {/* Customizable End Start  */}
        </Container>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ShortlistButton = styled(Button)(() => ({
  backgroundColor: "black",
  color: "white",
  padding: "15px 10px",
  marginBottom: "5px",
  fontSize: "14px",
  fontWeight: "bold",
  border: "none",
  cursor: "pointer",
  borderRadius: "5px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
}));
const AvailableBtn = styled(Button)(() => ({
  backgroundColor: "#00CC99",
  color: "black",
  height: 25,
  fontSize: "14px",
  cursor: "pointer",
  borderRadius: "5px",
  textTransform: "none",
  border: "1px solid #009be2",
  "&:hover": {
    backgroundColor: "#00CC99",
  },
}));

const modalStyle = {
  mainHeading: {
    fontFamily: "Silika-Medium",
    fontSize: "30px",
    marginTop: 20,
    marginBottom: 40,
  },
  outerMostContainerSize: {
    height: "600px",
    width: "100%",
    marginTop: "100px",
    marginBottom: "100px",
    paddingTop: 50,
    border: "1px solid #DFDFDF",
    position: "relative" as "relative",
  },
  expeNameDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  profileTitle: {
    color: "#000",
    fontFamily: "Silika-Medium",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.15px",
    marginTop: "12px",
  },
  socialHeadContainer: {
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
    marginTop: "20px",
  },
  socialHeadInnerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "1195px",
    background: "#000",
    padding: "20px",
    borderRadius: "4px, 4px, 0px, 0px",
  },
  addIconStyle: {
    color: "#FFF",
    height: "30px",
    width: "30px",
    cursor: "pointer",
  },
  socialMainContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    alignSelf: "center",
  },
  socialIconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  educationMainContainerOuterDiv: {
    height: "260px",
    maxWidth: "1093px",
    display: "flex",
    gap: "75px",
    margin: "auto",
    marginTop: "50px",
  },
  imageTemplate: {
    height: "75px",
    width: "75px",
    fill: "#D9D9D9",
  },
  mainDataContainer: {
    width: "100%",
  },
  titleContainer: {
    color: "#9B9B9D",
    display: "flex",
    fontFamily: "Silka",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "19.364px",
    alignItems: "flex-end",
  },
  locationContainer: {
    marginLeft: "12px",
    marginRight: "5px",
  },
  detailDataContainer: {
    marginTop: "16px",
    marginBottom: "28px",
  },
  summarisedData: {
    color: "#000",
    fontFamily: "Silika-ExtraLight",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "normal",
    marginBottom: "28px",
  },
  unavaBorderDiv: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  shareButton: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1%",
  },
  sharePaddingLeft: {
    paddingLeft: "10px",
  },
  imgSize: {
    width: "23px",
    visibility: "hidden",
  } as React.CSSProperties,
  imgPaddingLeft: {
    paddingRight: "10px",
    cursor: "pointer",
  },
};

// Customizable Area End
