import React from "react";

// Customizable Area Start
import { Box, Button, Switch, Typography} from "@material-ui/core";
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { forgewebLogo, poster } from "./assets";
import { ToastContainer } from "react-toastify";


export const StyledButton = styled(Button)(({ theme }) => ({
  width: '300px',
  marginTop: '10px', 
  [theme.breakpoints.up('sm')]: {
    width: '327px',
  },
  [theme.breakpoints.up('md')]: {
    width: '360px',
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: '50px', 
  },
}));

export const StyledLogo = styled(Box)(({ theme }) => ({
  width: '18.55px',
  height : "24px",
  [theme.breakpoints.up('sm')]: {
   width: '34px',
    height : "44px"
  },
  [theme.breakpoints.up('md')]: {
    width: '34px',
    height : "44px"
  }
}));

export const StyledButtonFloat = styled(Button)(({ theme }) => ({
  width: '100%',
  textTransform: 'capitalize', alignSelf: "flex-end",
  marginTop: "auto", marginBottom: "50px",

  [theme.breakpoints.up('sm')]: {
    width: '100%',
    marginTop: "auto",
  },
  [theme.breakpoints.up('md')]: {
    width: '129px', height: '48px', float: 'right',
    marginTop: 0, marginBottom: 0,
  },
}));

export const StyledBackButtonFloat = styled(Button)(({ theme }) => ({
  width: '300px',
  textTransform: 'capitalize', alignSelf: "flex-end",
  marginTop: "auto", marginBottom: "50px",
  display : "none",
  backgroundColor : "none",
  [theme.breakpoints.up('sm')]: {
    display : 'none'
  },
  [theme.breakpoints.up('md')]: {
    display : "block",
    width: '129px', height: '48px', float: 'right',
    marginTop: 0, marginBottom: 0,
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign : "left",
  [theme.breakpoints.up('sm')]: {
    textAlign: 'center', 
  }
}));


export const StyledBackButton = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.up('md')]: {
    display : "none" 
  }
}));

const StyledLeftDiv = styled(Box)(({ theme }) => ({
  display: "flex", 
  marginTop: "36px",
  [theme.breakpoints.up('sm')]: {
    display : "flex",
  }
}));

export const IOSSwitch = withStyles((theme) => ({
  root: {
    padding: 0,
    width: 48,
    height: 28,
  },
  switchBase: {
    padding: 1,
    top: 1,
    left: 1,
    '&$checked': {
      // transform: 'translateX(17px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        border: 'none',
        backgroundColor: "black ",
      },
    },
    "& .MuiSwitch-colorSecondary-50.Mui-checked + .MuiSwitch-track-54": {
      backgroundColor: '#00FFA7 !important'
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #F1F5F9',
    },
    '& #swith': {
      marginTop: "1px",
    },
    "& .MuiSwitch-root-45": {
      marginRight: '16px !important'
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 29 / 2,
    border: `0px solid ${theme.palette.grey[400]}`,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    backgroundColor: '#E2E8F0',
  },
  checked: {},
  focusVisible: {},
}))(Switch);


// Customizable Area End

import AccountLoginController, { Props } from "./AccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class LoginSignUp extends AccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    const { children } = this.props;
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Box
          paddingLeft={{ xs: "0px", sm: "24px" }}
          paddingRight={{ xs: "0px", sm: "24px" }}
          style={{ ...webStyle.loginOuterMostContainer, overflowY : "auto", overflowX : "hidden" }}
        >
          <Box
            width={{ xs: "100%" }}
            style={webStyle.leftDiv}
          >
            <div style={webStyle.logoOuterContainer}>
              <StyledLogo>
              <img onClick={(e) => {
                     this.handleNavigateMainPage()
                    }} src={forgewebLogo} alt="logo" style={webStyle.logoImg} />
              </StyledLogo>
             
            </div>

            <Box
              display={{ xs: "block", sm: "none" }}
              style={webStyle.borderBottom}
            >

            </Box>





            <StyledLeftDiv style={webStyle.logInLeft}>{children}</StyledLeftDiv>
          </Box>

          <Box
            display={{ xs: "none", sm: "block" }}
            style={webStyle.logInRight}
          >
            <img src={poster} alt="login Poster" style={webStyle.rightImage} />
          </Box>
          <ToastContainer />
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle = {
  loginOuterMostContainer: {
    display: "flex",
    height: "100vh"
  },
  leftDiv: {
    display: "flex",
    flexDirection: "column" as "column",
    width: "100%",
    height: "100%",
    overFlowY : "auto"
  },
  logoOuterContainer: {
    height : "48px",
    padding : "12px 0 12px 24px"
  },
  borderBottom: {
    borderBottom: "1px solid #DFDFDF",
  },
  logoImg: {
    height: "100%",
    width: "100%",
    flexShrink: 0,
    cover: "no-repeat",
    cursor : 'pointer'
  },
  logInLeft: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center"
  },
  leftContainer: {
    margin: "auto",
    gap: "8px",
    flexShrink: 0
  },
  formTitleContainer: {
    marginBottom: "45px",

  },
  formTitleText: {
    color: "#000",
    fontFamily: "Silika-Medium",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.12px",
  },
  inputfieldHeight: {
    height: "56px",
    width: '100%',
    marginBottom: 15,
  },
  inputfieldHeightWithError: {
    height: "56px",
    border: "1px solid #DC2626",
    borderRadius: "2px",
  },
  passwordInputPosition: {
    position: "relative" as "relative",
    marginTop: "26px",
  },
  isForgotPasswordOuterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "16px"
  },
  forgotPassword: {
    fontFamily: "Silika-Light",
    fontSize: "12px",
    fontWeight: 200,
    lineHeight: "22px",
    color: "#000000",
    textDecoration: "none",
  },
  isForgotPassword: {
    color: "#9B9B9D",
    fontFamily: "Silika-Light",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "22px",
    cursor: "pointer",
  },
  buttonMargin: {
    marginTop: "50px",
  },
  orDiv: {
    display: "flex",
    alignItems: "center",
  },
  borderOr: {
    borderBottom: "1px solid #DFDFDF",
    width: "165px",
  },
  or: {
    marginLeft: "8px",
    marginRight: "8px",
    color: "#9B9B9D",
    fontFamily: "Silika-Light",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
  },
  borderContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "25px",
    marginBottom: "18px",
  },
  logInButton: {
    height: "56px",
    borderRadius: "2px",
    color: "#FFF",
    backgroundColor: "black",
    fontFamily: "Silika-Medium",
    fontSize: '16px',
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    cursor: "pointer"
  },
  isAccountDiv: {
    display: "flex",
    justifyContent: "center",
  },
  isAccountStatement: {
    color: "#9B9B9D",
    fontFamily: "Silika-Light",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
  },
  signUplink: {
    color: "#000",
    fontFamily: "Silika-Light",
    cursor: "pointer",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    marginLeft: "8px",
  },
  errorMessage: {
    color: "#DC2626",
    fontFamily: "Silka",
    fontSize: "12px",
    fonStyle: "normal",
    fontWeight: 200,
    lineHeight: "normal",
    letterSpacing: "0.24px",
  },
  passwordVisibilityOffIcon: {
    position: "absolute" as "absolute",
    right: "20px",
    top: "16px",
    width: "24px",
    height: "24px",
    flexShrink: 0,
    color: "#9B9B9D",
    cursor: "pointer",
  },
  passwordVisibilityOnIcon: {
    position: "absolute" as "absolute",
    right: "20px",
    top: "16px",
    width: "24px",
    height: "24px",
    flexShrink: 0,
    color: "#000",
    cursor: "pointer",
  },
  logInRight: {
    width: "40%",
    height: "100vh",
    overflow : "hidden",
    position: 'sticky' as 'sticky', 
    top: 0, 
  },
  rightImage: {
    height: "100%",
    width: "100%",
    objectfit : 'cover'
  },
};

// Customizable Area End
