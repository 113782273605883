import React from "react";

// Customizable Area Start
import { Box, Typography, TextField } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Formik } from "formik";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import * as Yup from "yup";
import LoginSignUp, { webStyle,StyledButton } from "./LoginSignUp.web";
import AccountLoginController, { Props } from "./AccountLoginController";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password doesn't match the criteria above"
    ),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password"), null], "Password not matching"),
});



// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class UserForgotPassword extends AccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
   
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
          <div style={{overflow:'hidden'}}>
        <LoginSignUp navigation={this.props.navigation} id={""}>
        <Box width={{xs : "300px", sm : "327px",  md: "360px"}} style={webStyle.leftContainer}>
          <Formik
            initialValues={{
              password: this.state.newPassword,
              confirmPassword: this.state.confirmNewPwd,
            }}
           onSubmit={()=>{}}
            validateOnChange={false}
            validateOnBlur={true}
            data-testid="formikForm"
            validationSchema={validationSchema}
          >
            {({  handleSubmit }) => (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div style={{...webStyle.formTitleContainer,marginBottom:'25px',justifyContent: 'left'}}>
                  <Typography style={{...webStyle.formTitleText,textAlign: "left" as "left"}}>
                    New Password
                  </Typography>
                </div>
                <Box style={{...webStyle.passwordInputPosition}}>
                  <div>
                    <Typography
                      style={{
                        ...webStyle.isAccountStatement,
                        fontSize: "15px",
                        marginBottom: 13,
                        color: "black",
                        fontFamily:'Silika-Medium',
                      }}
                    >
                      Enter a new password for {sessionStorage.getItem('email')}
                    </Typography>
                  </div>
                  <div style={webStyle.isAccountDiv}>
                    <Typography
                      style={{
                        ...webStyle.isAccountStatement,
                        fontSize: "12px",
                        marginBottom: 5,
                      }}
                    >
                      The password should have at least one lower case and one
                      upper case characters
                    </Typography>
                  </div>
                  <TextField
                    fullWidth
                    error={!!this.state.forgotPwdError}
                    helperText={this.state.forgotPwdError}
                    style={{...webStyle.inputfieldHeight}}
                    variant="outlined"
                    autoComplete='new-password'
                    type={this.state.showForgotPwd ? "text" : "password"}
                    value={this.state.newPassword}
                    onBlur={()=>{this.validatePasswrd()}}
                    placeholder="Password"
                    data-test-id={"password"}
                    name="password"
                    onChange={(event) => {
                      this.handlePasswordChange(event.target.value)
                    }}
                    FormHelperTextProps={{
                      style: {
                        marginLeft: 0,
                      },
                    }}
                    InputProps={{
                      endAdornment: !this.state.showForgotPwd ? (
                        <VisibilityOffIcon
                          style={webStyle.passwordVisibilityOffIcon}
                          onClick={this.handlePwdShow}
                        />
                      ) : (
                        <VisibilityIcon
                          style={webStyle.passwordVisibilityOnIcon}
                          onClick={this.handlePwdShow}
                        />
                      ),
                    }}
                  />
                </Box>
                <Box style={{...webStyle.passwordInputPosition, marginTop : 0}}>
                  <TextField
                    fullWidth
                    style={{ ...webStyle.inputfieldHeight, marginBottom: 5 }}
                    error={!!this.state.confirmPwdError}
                    helperText={this.state.confirmPwdError}
                    variant="outlined"
                    onBlur={()=>{this.valdateConfirmPwd()}}
                    type={this.state.showConfirmPassword ? "text" : "password"}
                    value={this.state.confirmNewPwd}
                    placeholder="Confirm New Password"
                    data-test-id={"confirmPassword"}
                    name="confirmPassword"
                    onChange={(event) => {
                     this.handleConfirmPasswordChange(event.target.value)
                    }}
                    FormHelperTextProps={{
                      style: {
                        marginLeft: 0,
                      },
                    }}
                    InputProps={{
                      endAdornment: !this.state.showConfirmPassword ? (
                        <VisibilityOffIcon
                          style={webStyle.passwordVisibilityOffIcon}
                          onClick={this.handleForgotPwdShow}
                        />
                      ) : (
                        <VisibilityIcon
                          style={webStyle.passwordVisibilityOnIcon}
                          onClick={this.handleForgotPwdShow}
                        />
                      ),
                    }}
                  />
                </Box>
                <Box />
                  <StyledButton
                    variant="outlined"
                    data-test-id='submitBtn'
                    style={{...webStyle.logInButton,textTransform:'capitalize', marginBottom : "20px", marginTop : "36px"}}
                    type="submit"
                    onClick={() => {
                     this.handleClick();
                    }}
                  >
                    Set New Password
                  </StyledButton>
              </form>
            )}
          </Formik>
          </Box>
        </LoginSignUp>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
